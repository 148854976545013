import React from 'react'

type IIconProps = {
  color?: string
}

const LinkedinIcon: React.FC<React.SVGProps<SVGSVGElement> & IIconProps> = ({
  color = 'currentColor',
  ...rest
}) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13.83 14.55" {...rest}>
      <path
        fill={color}
        d="M3.1,14.55H.23V4.83H3.1ZM1.66,3.5A1.7,1.7,0,0,1,0,1.75,1.7,1.7,0,0,1,1.66,0,1.7,1.7,0,0,1,3.32,1.75,1.7,1.7,0,0,1,1.66,3.5ZM13.83,14.55H11V9.82c0-1.12,0-2.57-1.49-2.57S7.75,8.48,7.75,9.74v4.81H4.89V4.83H7.64V6.16h0a3,3,0,0,1,2.71-1.57c2.9,0,3.44,2,3.44,4.63Z"
      />
    </svg>
  )
}

export default LinkedinIcon
